<template>
  <div>
 
      <!-- colar form funcionario aqui  -->

      <div class="row justify-content-center align-items-md-center">
        <div class="col-md-12">
          <div class="card full-height bg-ligth">
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <h2>Dados Pessoais: funcionario</h2>
                  <!-- <div class="portlet light"> -->
                  <!-- <h3>Cadastrar</h3> -->

                  <div class="portlet-body">
                    <div class="row form-group col-md-12">
                      <!-- <h4 class="form-section">
                                        Dados pessoais:
                                      </h4> -->

                      <div class="col-md-4 pull-left">
                        <label class="col-md-12">Nome:*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.nome"
                          placeholder="Digite o nome..."
                        />
                      </div>
                      <div class="col-md-5 pull-left">
                        <label class="col-md-12">Sobrenome:*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.sobrenome"
                          placeholder="Digite o Sobrenome..."
                        />
                      </div>
                      <div class="col-md-3 pull-left">
                        <label class="col-md-12">D. nascimento:*</label>
                        <input
                          type="date"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.data_nascimento"
                          placeholder="Data de nascimento..."
                        />
                      </div>
                    </div>

                    <div class="row form-group col-md-12">
                      <div class="col-md-3 pull-left">
                        <label class="col-md-12">Pis:</label>
                        <input
                          maxlength="11"
                          type="email"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.pis"
                          placeholder="Digite o pis..."
                        />
                      </div>
                      <div class="col-md-3 pull-left">
                        <label class="col-md-12">Celular 1:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.telefone"
                          placeholder="Digite o celular..."
                        />
                      </div>
                      <div class="col-md-3 pull-left">
                        <label class="col-md-12">Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.email"
                          placeholder="Digite o email..."
                        />
                      </div>
                      <div class="col-md-3 pull-left">
                        <label class="col-md-12">CPF:*</label>
                        <input
                          maxlength="11"
                          type="text"
                          class="form-control"
                          id="cpf"
                          v-model="form.cpf"
                          placeholder="Digite o numero do documento..."
                        />
                      </div>
                      <div class="col-md-3 pull-left">
                        <label class="col-md-12">RG:*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="rg"
                          v-model="form.rg"
                          placeholder="Digite o numero do documento..."
                        />
                      </div>

                      <div class="col-md-2 pull-left">
                        <label class="col-md-12">Sexo:*</label>
                        <select v-model="form.sexo_id" class="form-control">
                          <option></option>
                          <option value="3">Masculino</option>
                          <option value="4">Femenino</option>
                        </select>
                      </div>
                    </div>
                    <div class="row form-group col-md-12">
                      <h4 class="col-md-12">Endereço:</h4>
                      <!-- <div class="col-md-2 pull-left">
                                  <label class="col-md-12">Tipo:*</label>
                                  <select
                                    
                                    v-model="form.tipo_rua_id"
                                    class="form-control"
                                  >
                                    <option></option>
                                    <option value="AV">AV</option>
                                    <option value="RUA">RUA</option>
                                  </select>
                                </div> -->

                      <div class="col-md-3 pull-left">
                        <label class="col-md-12">Endereço:*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.rua"
                          placeholder="Rua ...."
                        />
                      </div>
                      <!-- <div class="col-md-2 pull-left">
                                  <label class="col-md-12">Numero:*</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="form_control_1"
                                    v-model="form.numero"
                                    placeholder="numero ...."
                                  />
                                </div> -->
                      <div class="col-md-3 pull-left">
                        <label class="col-md-12">Cep:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.cep"
                          placeholder="Digite o complento..."
                        />
                      </div>
                      <div class="col-md-2 pull-left">
                        <label class="col-md-12">Bairro:*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.bairro"
                          placeholder="Digite o bairro..."
                        />
                      </div>
                    </div>
                    <div class="row form-group col-md-12">
                      <div class="col-md-2 pull-left">
                        <label class="col-md-12">Perfil:*</label>
                        <select v-model="form.perfil_id" class="form-control">
                          <option></option>

                          <option value="2">Funcionario</option>
                          <option value="3">cliente</option>

                        </select>
                      </div>
                      <div class="col-md-2 pull-left">
                        <label class="col-md-12">Relogio:*</label>
                        <select v-model="form.relogio_id" class="form-control">
                          <option></option>

                          <!-- <option value="1">Prefeitura</option>
                          <option value="2">Postinho</option>
                          <option value="3">garajam</option> -->
                          <option value="4">Mercado</option>

                        </select>
                      </div>
                      <div class="col-md-2 pull-left">
                        <label class="col-md-12">Identificação:*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.identificacao"
                          placeholder="Digite a identificao..."
                        />
                      </div>
                      <div class="col-md-2 pull-left">
                        <label class="col-md-12">Professao:*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.profissao"
                          placeholder="Digite a Profissao..."
                        />
                      </div>
                      <div class="col-md-2 pull-left">
                        <label class="col-md-12">Salario:*</label>
                        <input
                          type="text"
                          class="form-control"
                          id="form_control_1"
                          v-model="form.salario"
                          placeholder="Digite o Salario..."
                        />
                      </div>

                      <!-- <div class="col-md-2 pull-left">
                                  <label class="col-md-12">Contrato:*</label>
                                  <select
                                    
                                    v-model="form.contrato_funcionario_id"
                                    class="form-control"
                                  >
                                    <option></option>
                                    <option value="1">Contrato 2</option>
                                  </select>
                                </div> -->
                    </div>
                    <!-- <div class="row form-group col-md-12"> -->
                    <!-- <div class="col-md-3 pull-left">
                                  <label class="col-md-11 ml-2"
                                    >Descricao:*</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="form_control_1"
                                    v-model="form.descricao"
                                    placeholder="Digite o descricao..."
                                  />
                                </div> -->
                    <!-- </div> -->
                    <!-- <div class="row form-group col-md-12">
                                <div class="col-md-3 pull-left">
                                  <div class="md-radio-inline">
                                    <label>Status:*</label>
                                    <div class="md-radio">
                                      <input
                                        type="radio"
                                        id="radio1"
                                        checked=""
                                        value="1"
                                        v-model="form.status"
                                        class="md-radiobtn"
                                      />
                                      <label for="radio1">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span>
                                        Ativo
                                      </label>
                                    </div>
                                    <div class="md-radio has-error">
                                      <input
                                        type="radio"
                                        id="radio2"
                                        value="2"
                                        v-model="form.status"
                                        class="md-radiobtn"
                                      />
                                      <label for="radio2">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span>
                                        Desativado
                                      </label>
                                    </div>
                                    <div class="md-radio has-warning">
                                      <input
                                        type="radio"
                                        id="radio3"
                                        value="3"
                                        v-model="form.status"
                                        class="md-radiobtn"
                                      />
                                      <label for="radio3">
                                        <span></span>
                                        <span class="check"></span>
                                        <span class="box"></span>
                                        Suspenso
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div> -->
                  </div>

                  <div class="row justify-content-end">
                    <div class="ml-2"></div>
                    <div class="col-md-12 text-center">
                      <button
                        v-if="lista_campos == ''"
                        @click="confirm('cria')"
                        class="btn btn-primary"
                      >
                        salvar
                      </button>

                      <button
                        :disabled="verif"
                        v-if="lista_campos != ''"
                        @click="confirm('edita')"
                        class="btn btn-primary"
                      >
                        editar

                        <b-spinner
                          v-show="verif"
                          small
                          variant="dark"
                          type="grow"
                          label="Spinning"
                        ></b-spinner>
                      </button>
                    </div>
                  </div>

                  <!-- </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Vue from "vue";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast, {
  position: "top",
  duration: 3000,
});
export default {
  data() {
    return {
      form: {
        pis: "",
        nome: " ",
        sobrenome: "",
        nome_sobrenome: "",
        // estado_civil_id: 2,
        cpf: "",
        rg: "",
        data_nascimento: "",
        identificacao: "",
        cep: "",
        rua: "",
        bairro: "",
        salario: "",

        // semestre_id: "",
        // periodo_letivo_id: "",
        email: "",
        status: 2,
        profissao: "",
        sexo_id: "",
        // escolaridade_id: 1,

        telefone: "",
        // telefone2: "111111",
        perfil_id: "",
        relogio_id: "",
      },
      value: "",
    };
  },

  created() {
    this.preenxerCampos();
  },
  computed: {
    lista_campos() {
      return this.$store.state.ponto.lista_campos;
    },
    lista_aluno() {
      return this.$store.state.sistema.lista_aluno;
    },
    mensagem_alert() {
      return this.$store.state.ponto.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Esta seguro?",
        message: `Esta ação ` + tipo + ` um funcionario no sistema?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;

      await this.$store.dispatch("create_funcionario", this.form);
      this.verif = false;
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    async update() {
      this.verif = true;
      this.form.status = this.lista_campos.status;
      await this.$store.dispatch("update_funcionario", this.form);
      this.verif = false;
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
        if(this.mensagem_alert.tipo == "success"){
        this.$router.push({ name: "UsuarioPonto" });
        }

      });
    },
    preenxerCampos() {
      console.log(this.lista_campos);
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          pis: this.lista_campos.pis,
          nome: this.lista_campos.nome,
          sobrenome: this.lista_campos.sobrenome,
          nome_sobrenome: "",
          // estado_civil_id: 2,
          cpf: this.lista_campos.cpf,
          rg: this.lista_campos.rg,
          data_nascimento: this.lista_campos.data_nascimento,
          identificacao: this.lista_campos.identificacao,
          // cep: this.lista_campos.cep
          rua: this.lista_campos.rua,
          bairro: this.lista_campos.bairro,

          // semestre_id: "",
          // periodo_letivo_id: "",
          email: this.lista_campos.email,
          status: this.lista_campos.status,
          profissao: this.lista_campos.profissao,
          salario: this.lista_campos.salario,
          sexo_id: this.lista_campos.sexo_id,
          // escolaridade_id: 1,

          telefone: this.lista_campos.telefone,
          // telefone2: "111111",
          perfil_id: this.lista_campos.perfil_id,
          relogio_id: this.lista_campos.relogio_id,
        };
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>